import { Text } from "@fluentui/react-components";
import UseStyles from "../Styles";

const Privacy = () => {
  const styles = UseStyles();
  return (
    <div className={styles.root}>
      <div className={styles.privacy}>
        <Text size={500}>Yugen Apps - Privacy Policy</Text>
        <Text size={100}> &nbsp; </Text>

        <Text size={400}>How We Collect and Use Information</Text>

        <Text size={300}>Information you add into the app:</Text>
        <Text size={200}>
          Your input information such as images and parameters into the app that
          is used to complete the mosaic. None of this information is personally
          identifiable.
        </Text>

        <Text size={300}>How we use the information you add into the app:</Text>
        <Text size={200}>
          None of this information is ever used by us or any third party. We do
          not send this information to any outside parties.
        </Text>

        <Text size={300}>Analytics information:</Text>
        <Text size={200}>
          We may record diagnostic and usage information, such as the number of
          times you visited the settings page or the number of times you create
          a new mosaic. This data is anonymous and is not traceable to an
          individual. This information is tracked to determine which features
          are being used so as to help guide what types of features to add or
          remove in the future. The data that you input into the app, such as
          images, are not tracked.
        </Text>
        <Text size={100}> &nbsp; </Text>

        <Text size={400}>How We Store Your Information</Text>

        <Text size={300}>
          How the information you add into the app is saved or stored:
        </Text>
        <Text size={200}>
          The information you add into the app is saved in a database file in
          your local device. You are responsible for safeguarding your device to
          protect your information. We are not responsible for any confidential
          information. This data may be synchronized to our servers if you
          enable the sync feature.
        </Text>
        <Text size={100}> &nbsp; </Text>

        <Text size={400}>How to Contact Us</Text>
        <Text size={200}>
          Contact us at info@yugenapps.com if you have any questions regarding
          the privacy policy.
        </Text>
        <Text size={100}> &nbsp; </Text>

        <Text size={400}>Changes to Our Privacy Policy</Text>
        <Text size={200}>
          The publisher Yugen Apps may modify or update this Privacy Policy from
          time to time to reflect changes in our app, and so you should review
          this page periodically. When we change the policy in a material manner
          we will let you know and update the ‘last modified’ date at the bottom
          of this page.
        </Text>

        <Text size={100}>
          This privacy policy was last modified on May 18, 2020.
        </Text>
      </div>
    </div>
  );
};

export default Privacy;
