import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=ae9243a3-0cf3-4ce1-bede-3609a218d546;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});
appInsights.loadAppInsights();

export { reactPlugin };
