import Header from "./Header";
import Projects from "./Projects";
import UseStyles from "../Styles";
// import { WhatsNew } from "./WhatsNew";

const Home = () => {
  const styles = UseStyles();
  return (
    <div className={styles.root}>
      <div className={styles.home}>
        <Header />

        <div className={styles.projectsContainer}>
          <Projects />
        </div>

        {/* <WhatsNew/> */}
      </div>
    </div>
  );
};

export default Home;
