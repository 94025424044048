import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const headerURL = "/assets/img/headers/2.png";

const UseStyles = makeStyles({
  root: {
    justifyContent: "center",
    display: "flex",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
  privacy: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
  },
  home: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    height: "auto",
    boxSizing: "border-box",
    ...shorthands.gap("16px"),
  },
  headerCard: {
    height: "fit-content",
    width: "200px",
    "@media (min-width: 488px) and (max-width: 820px)": {
      width: "435px",
    },
    "@media (min-width: 820px)": {
      width: "670px",
    },
  },
  projectsContainer: {
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "240px",
    "@media (min-width: 488px) and (max-width: 820px)": {
      width: "460px",
    },
    "@media (min-width: 820px)": {
      width: "700px",
    },
    ...shorthands.gap("10px"),
  },
  projectCard: {
    height: "fit-content",
    width: "200px",
  },
  projectCardPreview: {
    ...shorthands.padding("25px"),
  },
  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },
  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },
  projectCardHeaderDescription: {
    justifyContent: "center",
    display: "flex",
    marginTop: "5px",
    ...shorthands.gap("4px"),
  },
  projectCardButtonIcon: {
    // width: "88px",
    // height: "88px",
    // backgroundColor: "#FFF",
    ...shorthands.padding("5px"),
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    ...shorthands.margin("2px"),
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  },
  projectCardHeaderCaption: {
    color: tokens.colorNeutralForeground3,
  },
  projectCardHeaderImage: {
    maxWidth: "44px",
    maxHeight: "44px",
    ...shorthands.borderRadius("4px"),
  },
});

export default UseStyles;
