import { LinksProperties } from "../models/LinksProperties";
import { LinkType } from "../models/LinkType";
import {
  FacebookLogoIcon,
  GitHubLogoIcon,
  DocumentationIcon,
  TwitterLogoIcon,
  HelpIcon,
  WebsiteIcon,
  ChatIcon,
} from "@fluentui/react-icons-mdl2";
import {
  LinkedInLogoIcon,
  NugetLogoIcon,
  StoreLogoMed20Icon,
} from "@fluentui/react-icons-mdl2-branded";

export const getLinkHref = (linksProperties: LinksProperties) => {
  let href: string = "";

  switch (linksProperties.type) {
    case LinkType.Discord:
      href = `https://discord.gg/${linksProperties.value}`;
      break;
    case LinkType.Docs:
      href = linksProperties.value;
      break;
    case LinkType.Facebook:
      href = `https://www.facebook.com/${linksProperties.value}`;
      break;
    case LinkType.GitHub:
      href = `https://github.com/${linksProperties.value}`;
      break;
    case LinkType.LinkedIn:
      href = `https://www.linkedin.com/in/${linksProperties.value}`;
      break;
    case LinkType.Nuget:
      href = `https://www.nuget.org/profiles/${linksProperties.value}`;
      break;
    case LinkType.Store:
      href = `https://www.microsoft.com/store/apps/${linksProperties.value}`;
      break;
    case LinkType.Twitter:
      href = `https://twitter.com/${linksProperties.value}`;
      break;
    case LinkType.Walkthroughs:
      href = linksProperties.value;
      break;
    case LinkType.Website:
      href = linksProperties.value;
      break;
  }

  return href;
};

export const getLinkIcon = (linksProperties: LinksProperties) => {
  let icon: JSX.Element;

  switch (linksProperties.type) {
    case LinkType.Discord:
      icon = <ChatIcon />;
      break;
    case LinkType.Docs:
      icon = <DocumentationIcon />;
      break;
    case LinkType.Facebook:
      icon = <FacebookLogoIcon />;
      break;
    case LinkType.GitHub:
      icon = <GitHubLogoIcon />;
      break;
    case LinkType.LinkedIn:
      icon = <LinkedInLogoIcon />;
      break;
    case LinkType.Nuget:
      icon = <NugetLogoIcon />;
      break;
    case LinkType.Store:
      icon = <StoreLogoMed20Icon />;
      break;
    case LinkType.Twitter:
      icon = <TwitterLogoIcon />;
      break;
    case LinkType.Walkthroughs:
      icon = <HelpIcon />;
      break;
    case LinkType.Website:
      icon = <WebsiteIcon />;
      break;
  }

  return icon;
};

export const getImageSrc = (imageSrc: string): string =>
  `./assets/img/cards/${imageSrc}`;
