import UseStyles, { headerURL } from "../Styles";
import { Card, Image } from "@fluentui/react-components";

const Header = () => {
  const styles = UseStyles();
  return (
    <Card className={styles.headerCard}>
      <Image shape="rounded" src={headerURL} />
    </Card>
  );
};

export default Header;
