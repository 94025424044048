import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from './services/AppInsights';
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home";
import Privacy from "./components/Privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
  },
]);

const App = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <FluentProvider theme={webLightTheme}>
        <RouterProvider router={router} />
      </FluentProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
