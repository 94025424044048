export enum LinkType {
  Discord = 'Discord',
  Docs = 'Docs',
  Facebook = 'Facebook',
  GitHub = 'GitHub',
  LinkedIn = 'LinkedIn',
  Nuget = 'Nuget',
  Store = 'Store',
  Twitter = 'Twitter',
  Walkthroughs = 'Walkthroughs',
  Website = 'Website'
}
