import { mergeClasses, Button, Card, CardHeader, CardPreview, Body1Strong } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { ProjectProperties } from "../models/ProjectProperties";
import { getImageSrc, getLinkHref, getLinkIcon } from "../services/LinkHelper";
import useStyles from "../Styles";

const Projects = () => {
  const styles = useStyles();
  const [projects, setProjects] = useState<ProjectProperties[]>([]);

  useEffect(() => {
    fetch("assets/projects.json")
      .then((response) => {
        if (response.ok) return response.json();
        else return Promise.reject("Didn't fetch text correctly");
      })
      .then((json) => {
        setProjects(json);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      {projects.map((project) => (
        <Card className={styles.projectCard} key={project.id}>
          <CardPreview
            className={mergeClasses(
              styles.projectCardPreview,
              styles.grayBackground
            )}
          >
            <img
              alt={project.title}
              className={styles.smallRadius}
              src={getImageSrc(project.imageSrc)}
            />
          </CardPreview>
          <CardHeader
            header={<Body1Strong>{project.title}</Body1Strong>}
            description={
              <div className={styles.projectCardHeaderDescription}>
                {project.links.map((link) => (
                    <Button
                    className={styles.projectCardButtonIcon}
                    href={getLinkHref(link)}
                    icon={getLinkIcon(link)}
                    key={link.value}
                    target="_blank"
                    as="a"
                    size="large"
                  />
                ))}
              </div>
            }
          />
        </Card>
      ))}
    </>
  );
};

export default Projects;

/* 
<Card className={styles.projectCard}>
  <CardPreview
    className={mergeClasses(
      styles.projectCardPreview,
      styles.grayBackground
    )}
    logo={
      <img
        className={styles.projectCardButtonIcon}
        alt={project.title}
        src={getImageSrc(project.imageSrc)}
      />
    }
  >
    <img
      alt={project.title}
      className={styles.smallRadius}
      src={getImageSrc(project.imageSrc)}
    />
  </CardPreview>
  <CardHeader
    image={{
      as: "img",
      className: styles.projectCardHeaderImage,
      src: getImageSrc(project.imageSrc),
    }}
    image={
      <Avatar
        image={{
          src: getImageSrc(project.imageSrc),
        }}
      />
    }
    header={<Body1 weight="semibold">{project.title}</Body1>}
    description={
      <Caption1
        className={mergeClasses(styles.projectCardHeaderDescription, styles.projectCardHeaderCaption)}
      >
        <span>{project.description}</span>
      </Caption1>
    }
    description={
      <Caption1 className={styles.projectCardHeaderCaption}>
        {project.description}
      </Caption1>
    }
    description={
      <img
        className={styles.projectCardButtonIcon}
        alt={project.title}
        src={getImageSrc(project.imageSrc)}
      />
    }
    description={
      <div className={styles.projectCardHeaderDescription}>
        {project.links.map((link) => getLink(link))}
      </div>
    }
    action={
      <Avatar
        image={{
          src: getImageSrc(project.imageSrc),
        }}
      />
    }
  />
  <span>{project.links.map((link) => getLink(link))}</span>
</Card> 
*/
